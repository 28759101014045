<template>
    <div class="main-section pa-5">
        <div class="hb-font-body-medium mb-4">EFTPOS Payment</div>
        <div class="hb-font-body-medium mb-3">Transactions Details</div>
        <p class="hb-font-body">Return Total: {{ totalPayment | formatMoney }}</p>
        <v-col class="pt-1 px-0">
            <HbTextField
                v-model="returnData.reference_name"
                width="100%"
                full
                v-validate="'required|max:45'"
                data-vv-scope="default"
                data-vv-name="referenceName"
                data-vv-as="reference"
                :error="errors.has('default.referenceName')"
                placeholder="Reference Name"
            />
        </v-col>
        <v-col class="pt-1 px-0">
            <HbTextField
                width="100%"
                full
                v-model="returnData.notes"
                v-validate="'required'"
                data-vv-scope="default"
                data-vv-name="notes"
                data-vv-as="notes"
                :error="errors.has('default.notes')"
                placeholder="Enter notes"
            />
        </v-col>
    </div>
</template>

<script>
export default {
    name: "ReturnEFTPOS",
    data () {
        return {
            
        }
    },
    props: {
        totalPayment: {
            type: Number,
            default: 0
        },
        returnData: {
            type: Object,
            default: {}
        }
    },
}
</script>